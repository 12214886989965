import React, { useEffect, useContext } from 'react';
import { string, shape, bool, number } from 'prop-types';
import { Col } from '@thd-olt-component-react/grid';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import classNames from 'classnames';
import { FullCard } from './FullCard';
import { GuideZoneCard } from './GuideZoneCard';
import { RentalZoneCard } from './RentalZoneCard';
import { InstallZoneCard } from './InstallZoneCard';
import { getCardType } from './Helpers';
import Model from './model/Model';
import { dataModel } from './dataModel';
import './category-cards-desktop.scss';
import { useCategoryCard } from '../hooks/useCategoryCard';

const CategoryCardsDesktop = ({
  keyword,
  navParam,
  storeId,
  data: propData,
  loading: propLoading,
  error: propError,
  componentPosition,
  ssr,
  category,
  noMargin
}) => {
  const { store, clientStore } = useContext(ExperienceContext);
  const { data, loading, error } = useCategoryCard({
    clientStore,
    store,
    storeId,
    keyword,
    navParam,
    propLoading,
    propData,
    propError,
    ssr
  });
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('category-cards.ready'); }, []);
  const categoryCardsContainerImpression = {
    id: '',
    component: 'CategoryCards',
    name: 'CategoryCards',
    type: 'content'
  };
  if (data?.searchModel?.orangeGraph?.universalSearchArray.length > 0) {
    // eslint-disable-next-line no-param-reassign
    category = data?.searchModel?.orangeGraph?.universalSearchArray[0].info.title;
  }

  if (!keyword && !navParam && typeof propLoading === 'undefined') {
    return null;
  }
  /* eslint-disable-next-line react/prop-types */
  const renderCardByType = ({ header, details, type, major }) => {
    switch (type) {
    case 'guide':
      return (
        <GuideZoneCard
          major={major}
          header={header}
          details={details}
          componentPosition={componentPosition}
          category={category}
        />
      );
    case 'rental':
      return (
        <RentalZoneCard
          major={major}
          header={header}
          details={details}
          componentPosition={componentPosition}
          category={category}
        />
      );
    case 'install':
      return (
        <InstallZoneCard
          major={major}
          header={header}
          details={details}
          componentPosition={componentPosition}
          category={category}
        />
      );
    default:
      return null;
    }
  };

  const renderFull = (categoryCards) => {
    const { majorDetails, majorHeader } = categoryCards;

    if (!majorHeader || !majorDetails) return null;

    const cardType = getCardType(majorHeader.title);

    const renderFullClassName = classNames(
      `sui-bg-primary 
      sui-w-full 
      sui-flex 
      sui-border-b 
      sui-border-r 
      sui-border-primary 
      ${noMargin ? 'sui-m-0' : 'sui-ml-4 sui-mb-4'}`,
    );

    return (
      <ImpressionProvider data={categoryCardsContainerImpression}>
        <div
          className={renderFullClassName}
          data-component="CategoryCardsDesktop"
          id={`${data.id}-${data.component}-${componentPosition}`}
          data-component-position={componentPosition}
        >
          <Col className="category-cards__zone-wrapper category-cards__full-card">
            <FullCard
              type={cardType}
              header={majorHeader}
              details={majorDetails}
              componentPosition={componentPosition}
              category={category}
            />
          </Col>
        </div>
      </ImpressionProvider>
    );
  };

  const renderMultipleCards = (categoryCards) => {
    const { majorDetails, minorDetails, majorHeader, minorHeader } = categoryCards;
    return (
      <>
        {renderCardByType({
          header: majorHeader,
          details: majorDetails,
          type: getCardType(majorHeader.title),
          major: true
        })}
        {renderCardByType({
          header: minorHeader,
          details: minorDetails,
          type: getCardType(minorHeader.title),
          major: false
        })}
      </>
    );
  };

  const renderZone = (categoryCards) => {
    const { majorDetails, minorDetails, majorHeader, minorHeader } = categoryCards;
    if (!majorHeader || !majorDetails || !minorHeader || !minorDetails) return null;

    return (
      <ImpressionProvider data={categoryCardsContainerImpression}>
        <Col
          className="category-cards"
          data-component="CategoryCardsDesktop"
          id={`${data.id}-${data.component}-${componentPosition}`}
          data-component-position={componentPosition}
        >
          <div className="category-cards__zone-wrapper category-cards__zone-card">
            {renderMultipleCards(categoryCards)}
          </div>
        </Col>
      </ImpressionProvider>
    );
  };

  if (loading && !data) {
    return <div data-component="CategoryCardsDesktopPlaceholder">Loading...</div>;
  }

  if (!data || !data?.searchModel?.orangeGraph || (!data && error)) {
    return null;
  }

  const categoryCards = new Model(data.searchModel.orangeGraph);

  return categoryCards.isFullCard('desktop')
    ? renderFull(categoryCards)
    : renderZone(categoryCards);
};

CategoryCardsDesktop.displayName = 'CategoryCardsDesktop';

CategoryCardsDesktop.dataModel = dataModel;

CategoryCardsDesktop.propTypes = {
  keyword: string,
  navParam: string,
  data: shape({}),
  loading: bool,
  error: shape({}),
  ssr: bool,
  storeId: string,
  componentPosition: number,
  category: string,
  noMargin: bool
};

CategoryCardsDesktop.defaultProps = {
  keyword: null,
  navParam: null,
  data: undefined,
  loading: undefined,
  error: undefined,
  ssr: false,
  storeId: null,
  componentPosition: 1,
  category: '',
  noMargin: false
};

export { CategoryCardsDesktop };
