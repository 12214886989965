/* eslint-disable react/no-array-index-key */
import React, {
  useRef, useCallback, useContext, useEffect, useState, useMemo
} from 'react';
import PropTypes, { string, number, bool, object } from 'prop-types';
import { BrowseSearchRedirector, DynamicComponent } from '@thd-nucleus/app-render';
import {
  client,
  extend,
  QueryProvider,
  QueryContext,
  hoist,
  useLazyDataModel,
  params,
  bool as boolType,
  shape as shapeType,
  string as stringType,
  number as numberType,
  alias,
  arrayOf
} from '@thd-nucleus/data-sources';
import { debounce } from '@thd-olt-functional/utils';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { CategoryCardsDesktop } from '@thd-olt-component-react/category-cards';
import { CompareTray } from '@thd-olt-component-react/compare';
import { EmtQuestionsAndAnswersImporter, BrandCategories } from '@thd-olt-component-react/emt-generated-content';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { EtchWidgets } from '@thd-olt-component-react/etch-widgets';
import { Hydrator, declareContexts } from '@thd-olt-component-react/hydrator';
import { HybridContentfulWidgets, useHybridPLP } from '@thd-olt-component-react/hybrid';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { useHelmet } from '@thd-nucleus/thd-helmet';
import { RectShape, TextBlock } from '@thd-olt-component-react/loading-placeholder';
import { Metadata, BrowseSearchMetadata } from '@thd-olt-component-react/metadata';
import { NoResultsFound } from '@thd-olt-component-react/no-results-found';
import { ProductPodPlaceholder } from '@thd-olt-component-react/product-pod';
import {
  ProductPodGroup,
  CustomProductPodDetails
} from '@thd-olt-component-react/product-pod-group';
import {
  ProductResults,
  ResultsApplied,
  ResultsFilterAndSort,
  ResultsFilterAndSortDrawer,
  ResultsHeader,
  ResultsPagination,
  ResultsToggleLayout,
  ResultsWrapped,
  ResultsSortBy,
  ResultsDimensions,
  useProductResults,
  productDefaultResultsDataModel,
  updateQueryParams,
  parseUrl,
  ProductResultsContext
} from '@thd-olt-component-react/product-results';
import { FlooringDrawer } from '@thd-olt-component-react/flooring-picker';
import { ProductTableView } from '@thd-olt-component-react/product-table-view';
import { RelatedSearch } from '@thd-olt-component-react/related-search';
import { RentalIntentDesktop } from '@thd-olt-component-react/rental-intent';
import { useNavigate, useLocation } from '@thd-olt-component-react/router';
import { SearchFeedback } from '@thd-olt-component-react/search-feedback';
import { useThdCustomer, CustomerContext } from '@thd-olt-functional/customer-information';
import {
  SponsoredBanner,
  SponsoredSkyscraperBanner,
  SponsoredInGridBanner
} from '@thd-olt-component-react/sponsored-content';
import { RetailMediaCarousel } from '@thd-olt-component-react/retail-media-pla-sponsored-placements';
import {
  DiscoveryZonesWrapper,
  PlpRecsRenderPlaceholder,
  PlpRvRenderPlaceholder,
  BuyItAgain
} from '@thd-olt-component-react/thd-recs-containers';
import { TrackOrder } from '@thd-olt-component-react/track-order';
import { VisualCategoryNavigation } from '@thd-olt-component-react/visual-navigation';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { OrderSample, usePLPSamples } from '@thd-olt-component-react/order-sample';
import { ConnectedTranslatorWidget } from '@thd-olt-component-react/translator_widget';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import classnames from 'classnames/bind';
import {
  EmtGeneratedContentLoad, EmtGeneratedContent, EmtGeneratedContentModel,
  EmtLinksLoad, EmtLinks, EmtLinksModel,
} from './dynamic-imports';
import {
  getBrowseAndSearchItemIds,
  limitProductsToDisplay,
  impProductPodData,
  impPLPRecs,
  imagePreloader,
  isRefinedPage,
  isNIncluded
} from './browse-and-search-utils';
import {
  CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER,
  KEY_PRODUCT_FEATURE_POD_CATEGORIES, DEFAULT_LAYOUT_STYLE,
  HYDRATION_STANDARD_SCROLL_BUFFER, RECS_REQUEST_KEY, CHECK_CAT_STYLE,
  BRAND_PAGE, HYBRID_LAYOUT
} from '../../constants';
import { CustomProductPodResponsive } from './CustomProductPodResponsive';

const HoistedProductPodGroup = hoist(
  ProductPodGroup, CategoryCardsDesktop, CustomProductPodDetails,
  CustomProductPodResponsive
);
export const BrowseAndSearch = (props) => {
  declareContexts([ProductResultsContext, QueryContext, ExperienceContext, CustomerContext]);
  const navigate = useNavigate();
  const thdCustomer = useThdCustomer();
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const {
    isServer,
    clientStore,
    cookie,
    deliveryZip: serverDeliveryZip,
    dataType,
    customer
  } = useContext(ExperienceContext);
  const { search: querystring } = useLocation();
  const {
    pageType,
    prefetchPip,
    productPodHoverDelay,
    pathname,
    customerType,
    instance
  } = props;
  const path = `${pathname}${querystring}`;
  const checkShowProduct = path.includes(CHECK_CAT_STYLE) || false;
  const isSearch = pageType === 'search';
  const isBrowse = pageType === 'browse';
  const isTableViewEnabled = useConfigService('fs-prop:isTableViewEnabled');
  let isTableView = false;
  if (isTableViewEnabled) {
    isTableView = dataType === 'expanded-product-data';
  }

  // Discovery Zones state management for dynamic placement and rendering
  const [discoveryZoneContainerIndex, setDiscoveryZoneContainerIndex] = useState({ zone1: 8, zone2: 16 });

  const [inGridBannerIndex, setInGridBannerIndex] = useState(null);
  const [layoutStyle, setLayoutStyle] = useState(isTableView ? 'Table' : DEFAULT_LAYOUT_STYLE);
  const prefetchedSkuSet = useRef(new Set());
  const disableSSR = typeof window !== 'undefined' && window.DISABLE_SSR;
  const varnish = !disableSSR && !isSearch;
  const { storeId, storeZip, isLocalized } = useStore({ varnish, online: false });
  const pageLoadVariables = useRef(null);
  const isNoResultsFoundRef = useRef(false);
  const SERVER_SIDE_PRODUCT_POD_RENDER_COUNT = 12;
  const SERVER_SIDE_PRODUCT_POD_FETCH_COUNT = isSearch ? 24 : 12;
  const CLIENT_SIDE_PRODUCT_POD_COUNT = 24;
  const searchNavPropsRef = useRef({
    pageSize: disableSSR ? CLIENT_SIDE_PRODUCT_POD_COUNT : SERVER_SIDE_PRODUCT_POD_FETCH_COUNT,
    askForSponsored: isSearch,
    deliveryZip: serverDeliveryZip,
  });
  const isDiscoveryZonesEnabled = useConfigService('fs-prop:isDiscoveryZonesEnabled');
  const isDiscoveryZonesSearchEnabled = useConfigService('fs-prop:isDiscoveryZonesSearchEnabled');
  const sponsoredCarouselConfig = useConfigService('fs-prop:tnt-carouselType');
  const viewAllFilterButton = useConfigService('fs-prop:isViewAllFilterButtonEnabled');
  const viewPrimaryFilters = useConfigService('fs-prop:isviewPrimaryFiltersEnabled');
  const isFulfillmentRedesignEnabled = useConfigService('fs-prop:isFulfillmentRedesignEnabled');
  const viewVizNavFilters = useConfigService('fs-prop:isViewVizNavFiltersEnabled');
  const filterBrowseRecs = useConfigService('fs:browseFilteredRecs');
  const isGetItFastOnPlp = useConfigService('fs-prop:isGetItFastOnPlp');
  const isInGridBannerEnabled = useConfigService('fs-prop:isInGridBannerEnabled');
  // Feature Switch that enables hiding or showing pickup and delivery messaging based on filter selection.
  const isFulfillmentFSEnabled = useConfigService('fs:isFulfillmentFilterAndMsgEnabled');

  // Combine these flags into a single condition we can check more easily.
  const isDiscoveryZonesEligible = (isBrowse && isDiscoveryZonesEnabled) || (isSearch && isDiscoveryZonesSearchEnabled);
  const shouldShowSponsoredCarousel = useMemo(() => {
    return (sponsoredCarouselConfig === 'standardDZ'
      || sponsoredCarouselConfig === 'longtailDZ'
      || sponsoredCarouselConfig === 'fourStarDZ');
  }, [sponsoredCarouselConfig]);

  const theme = useTheme(BrowseAndSearch);
  const { hideSponsoredCarousel, hideFFMFilters, hideFeedback } = theme.props;
  let THDCustomer = {};
  if (clientStore) {
    let deliveryZipCode;
    if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
      deliveryZipCode = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
    }
    searchNavPropsRef.current = {
      pageSize: CLIENT_SIDE_PRODUCT_POD_COUNT,
      askForSponsored: true,
      deliveryZip: deliveryZipCode?.zipcode,
    };
    THDCustomer = typeof window !== 'undefined' ? window.THDCustomer?.default : {};
  }

  const { isClientResolved, setDefaultVariables } = useContext(QueryContext);

  const onStoreChange = ({ output }) => {
    const { storeId: updatedStoreId } = output || {};
    if (!updatedStoreId) return;

    if (window && window.THD_LOCALIZER_AUTO_INIT && window.THD_LOCALIZER_AUTO_INIT.Localizer) {
      window.THD_LOCALIZER_AUTO_INIT.Localizer
        .localize({ storeId: updatedStoreId })
        .then(() => {
          window.location.reload();
        });
    }
  };

  const makeStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (el) {
      const headerClass = ['Header3-sticky-desktop'];
      el.classList.add(...headerClass);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleScroll = useCallback(() => {
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-results.change-store', onStoreChange);
    window.LIFE_CYCLE_EVENT_BUS.trigger('header.header-positioning-layout', { position: 'sticky' });
    makeStickyHeader();
  }, []);

  const onDimensionsChange = useCallback(({ refinement }) => {
    if (!viewPrimaryFilters) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements.click', refinement);
    } else if (refinement?.dimensionName === 'Get It Fast') {
      window.LIFE_CYCLE_EVENT_BUS.trigger('filters-refinements-visualNav.click', refinement);
    }
    navigate(refinement.url);
  });

  const resultsDimensionsRef = useRef(null);
  const onPageChange = useCallback(({ url }) => {
    navigate(url);
    const { offsetTop } = resultsDimensionsRef?.current?.parentElement?.parentElement;
    window.scrollTo(0, offsetTop || 0);
  });

  const onSortChange = useCallback(({
    sortby, sortorder, name, currentPage, page, updatedPath = null
  }) => {
    const newPath = updateQueryParams(updatedPath || path, [
      {
        paramName: 'Nao',
        paramValue: null
      },
      {
        paramName: 'sortorder',
        paramValue: sortorder
      },
      {
        paramName: 'sortby',
        paramValue: sortby
      }
    ]);
    window.LIFE_CYCLE_EVENT_BUS.trigger('sort-refinements.click', {
      name,
      currentPage,
      page
    });
    navigate(newPath);
  });

  const cleanPath = path.replace(/^\/render[^/]*/, '');

  const isEtchVisNavOnPLP = (data) => {
    try {
      const stringifiedPartialTemplates = data?.searchModel?.partialTemplates;
      const etchVisualNavExists = (stringifiedPartialTemplates?.[0] || '').indexOf('visualNavigation') > -1;
      const hasPLPBanner = data?.searchModel?.metadata?.hasPLPBanner;
      const isPLPPage = data?.searchModel?.metadata?.contentType === 'plppage';
      return hasPLPBanner && etchVisualNavExists && isPLPPage;
    } catch (err) {
      return false;
    }
  };

  const {
    keyword,
    lowerbound,
    Nao = 0,
    sortby,
    nValue = '',
    sortorder,
    upperbound,
    catStyle,
    skipSpellCheck
  } = parseUrl({ path: cleanPath });

  // For hybrid-contentful-plp
  const hybridParams = {
    slug: isNIncluded(nValue) ? nValue : 'N-' + nValue,
    customerType,
    instance,
    storeId,
    skip: isSearch || isRefinedPage(pathname),
    isShowProducts: checkShowProduct
  };
  const { data: hybridData, contentElements, loading: hybridLoading } = useHybridPLP(hybridParams);
  useHelmet('general-merchandise', { hybridData }, imagePreloader, [hybridData]);
  const layouts = hybridData?.layouts;
  const layoutID = layouts?.id; // for impressions
  const isHybridLayout = layoutID && layouts?.content?.__typename === HYBRID_LAYOUT; // for validation
  const isBrandPage = layouts?.content?.pageType === BRAND_PAGE;

  const showKeyProductFeaturePods = KEY_PRODUCT_FEATURE_POD_CATEGORIES.find((value) => nValue.includes(value));
  const store = useStore({ varnish, online: false });
  const loadedStore = useRef(store);
  const localizedAndClientLoaded = useRef(false);
  const timeoutRef = useRef(null);
  const [productPageChunkLoaded, setProductPageChunkLoaded] = useState(false);
  const additionalSearchParams = {};
  const hasSponsoredSorts = !sortby || ['bestmatch', 'topsellers'].indexOf(sortby) > -1;
  if (searchNavPropsRef.current.askForSponsored && hasSponsoredSorts && cookie?.adobeCookie?.MCMID) {
    additionalSearchParams.sponsored = true;
    additionalSearchParams.mcvisId = cookie.adobeCookie.MCMID;
  }
  if (searchNavPropsRef.current.deliveryZip) {
    additionalSearchParams.deliveryZip = searchNavPropsRef.current.deliveryZip;
  }
  const showProducts = /showproducts/i.test(catStyle);
  if (showProducts) {
    additionalSearchParams.plp = true;
  }
  if (skipSpellCheck) {
    additionalSearchParams.skipSpellCheck = skipSpellCheck;
  }
  // only populated for non-cacheable pages (e.g search)
  if (customer?.svocId) {
    additionalSearchParams.svocID = customer?.svocId;
  }
  if (Object.keys(THDCustomer).length !== 0 && THDCustomer.svocID) {
    additionalSearchParams.svocID = THDCustomer.svocID;
  }

  const opts = {
    additionalSearchParams,
    keyword,
    lowerbound,
    nValue,
    pageSize: searchNavPropsRef.current.pageSize,
    storeId,
    start: Nao,
    sortby,
    sortorder,
    upperbound,
    isCustomerIdentified: isLocalized ? isCustomerIdentified : false,
  };
  const response = useProductResults(opts);

  const {
    data, error, loading, variables, responseLoading
  } = response;

  const categoryCard = data?.searchModel?.orangeGraph?.universalSearchArray.length > 0;
  const isExperienceResolved = isClientResolved({ queryName: 'searchModel' });
  useEffect(() => {
    const onResize = debounce(200, () => {
      if (isExperienceResolved) {
        const products = data?.searchModel?.products;
        // 4 pod layout
        if (window.innerWidth >= 1280) {

          if (categoryCard) {
            if (products?.length >= 24) {
              setInGridBannerIndex(24);
            } else if (products?.length <= 24) {
              setInGridBannerIndex(-1);
            }
          }
          if (!categoryCard) {
            if (products.length >= 12) {
              setInGridBannerIndex(12);
            } else {
              setInGridBannerIndex(-1);
            }
          }
          // 3 pod layout
        } else if (window.innerWidth >= 1024) {
          if (categoryCard) {
            if (products?.length >= 18) {
              setInGridBannerIndex(18);
            } else {
              setInGridBannerIndex(-1);
            }
          }
          if (!categoryCard) {
            if (products.length >= 9) {
              setInGridBannerIndex(9);
            } else {
              setInGridBannerIndex(-1);
            }
          }
        }
      }
    });
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
    }

    onResize();

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
      }
    };
  }, [isExperienceResolved]);

  const isBuyitagainEnabled = useConfigService('fs-prop:isBuyitagainEnabled');
  const cleanedPath = (pathname || '').replace(/render-\w+\//, '');
  if (!pageLoadVariables.current) {
    pageLoadVariables.current = variables;
    setDefaultVariables(variables);
  }
  if (data && !loading && !response?.responseLoading && isLocalized) {
    localizedAndClientLoaded.current = true;
    loadedStore.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
    };
  }
  const handleProductClick = useCallback((evt, product, action, sponsoredClickValues) => {
    let canonicalUrl = product?.identifiers?.canonicalUrl;
    if (canonicalUrl && !(evt.ctrlKey || evt.metaKey)) {
      evt.preventDefault();
      navigate(canonicalUrl);
    }
  }, []);
  const isCategory = (data?.searchModel?.metadata?.contentType
    && data.searchModel.metadata.contentType === 'categorypage');

  const products = limitProductsToDisplay({
    products: data?.searchModel?.products,
    setLimit: isServer && isSearch,
    limitCount: SERVER_SIDE_PRODUCT_POD_RENDER_COUNT
  });

  const stringifyItemIds = (hookData) => {
    return hookData?.searchModel?.products?.map(({ itemId: sku }) => sku)
      .join('-');
  };

  // Get Sample Products
  const { loading: samplesLoading } = usePLPSamples({
    products,
    productsFinishedLoading: searchNavPropsRef.current.pageSize === CLIENT_SIDE_PRODUCT_POD_COUNT
      && !responseLoading,
    storeId,
    storeZip
  });

  const stringifyAppliedRefinements = (hookData) => {
    const appliedDimensions = hookData?.searchModel?.appliedDimensions || [];
    return appliedDimensions.map(({ refinements = [] }) => {
      return refinements.map((refinement) => refinement.refinementKey).join('-');
    }).join('-');
  };

  const hasInStoreFilter = (resultsData) => {
    const { searchModel } = resultsData || {};
    const { appliedDimensions } = searchModel || {};
    const getItFast = (appliedDimensions || [])
      .filter((dimension) => (dimension.label || '').toUpperCase() === 'GET IT FAST');
    if (!getItFast.length) {
      return false;
    }
    const storePickUp = getItFast
      .find((dimension) => dimension.refinements
        .find((refinement) => refinement.label === 'Pick Up Today'));
    return !!storePickUp;
  };

  const [selectedForCompare, setSelectedForCompare] = useState([]);

  const onSelectedForCompare = (id, selected = false) => {
    if (selected) {
      setSelectedForCompare([...selectedForCompare, id]);
    } else {
      setSelectedForCompare(selectedForCompare.filter((item) => item !== id));
    }
  };

  const onCompare = () => {
    let queryparams = selectedForCompare
      .map((item, idx) => `item${idx + 1}=${item}`)
      .join('&');

    queryparams += hasInStoreFilter(data) ? '&inStoreFilter=true' : '';

    navigate(`/compare?${queryparams}`);

  };

  useEffect(() => {
    const itemIds = window.sessionStorage.getItem('compare');
    window.sessionStorage.removeItem('compare');
    if (itemIds) {
      setSelectedForCompare(JSON.parse(itemIds));
    }
  }, []);

  useEffect(() => {
    const onResize = debounce(200, () => {
      if (window.innerWidth >= 1280) {
        setDiscoveryZoneContainerIndex({ zone1: 8, zone2: 16 });
      } else if (window.innerWidth >= 1024) {
        setDiscoveryZoneContainerIndex({ zone1: 6, zone2: 12 });
      } else if (window.innerWidth >= 640) {
        setDiscoveryZoneContainerIndex({ zone1: 4, zone2: 8 });
      }
    });

    if (isDiscoveryZonesEligible) {
      window.addEventListener('resize', onResize);
    }

    onResize();
    return () => {
      if (isDiscoveryZonesEligible) {
        window.removeEventListener('resize', onResize);
      }
    };
  }, [isDiscoveryZonesEligible]);

  const resolved = isClientResolved({ queryName: 'searchModel' });
  useEffect(() => {
    const analyticsSearchModel = {
      ...data?.searchModel || { searchReport: { ...searchNavPropsRef.current, startIndex: Nao } },
      defaultPageSize: searchNavPropsRef.current.pageSize,
      isTableView: layoutStyle === 'Table',
      pageID: isNIncluded(nValue) ? nValue : 'N-' + nValue,
      isHybridContentful: isHybridLayout
    };
    if (isBrowse) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('browse.new-products', analyticsSearchModel);
    } else if (isSearch) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('search.new-products', analyticsSearchModel);
    }
  }, [stringifyItemIds(data), stringifyAppliedRefinements(data)]);

  const decideFullWidthClasses = isGetItFastOnPlp
    ? 'sui-col-span-12'
    : 'sui-col-span-12 sm:sui-col-span-8 md:sui-col-span-9 lg:sui-col-span-10';

  const [resultsColClasses, setResultsColClasses] = useState(decideFullWidthClasses);

  const resultsColSize = classnames(
    { 'sui-col-span-12 sm:sui-col-span-9': !isTableView },
    { 'sui-col-span-12 sm:sui-col-span-8': isTableView && layoutStyle !== 'Table' },
    { 'sui-col-span-12 sm:sui-col-span-11': isTableView && layoutStyle === 'Table' }
  );
  const handleLayoutToggle = (style) => {
    setLayoutStyle(style);
  };
  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('LAYOUT_MANAGER.ADD_FS', (evt) => {
      if (evt.output['fs-disable:mf-dimensions__mf-results-dimensions'] === 'true') {
        setResultsColClasses('sui-col-span-12');
      }
      if (evt.output['fs-disable:mf-dimensions__mf-results-dimensions'] === 'false') {
        setResultsColClasses('sui-col-span-8 sm:sui-col-span-9 md:sui-col-span-10');
      }
    });

    window.LIFE_CYCLE_EVENT_BUS.on('CONFIG_SERVICE_CHANGE', (evt) => {
      if (evt.output['fs-prop:isTableViewEnabled'] === 'true') {
        setLayoutStyle(dataType === 'expanded-product-data' ? 'Table' : DEFAULT_LAYOUT_STYLE);
      } else if (evt.output['fs-prop:isTableViewEnabled'] === 'false') {
        setLayoutStyle(DEFAULT_LAYOUT_STYLE);
      }
    });
  }, []);
  const prefetchPipRef = useRef({
    prefetchPip: alias('product').params({ itemId: stringType() }).shape({
      itemId: stringType()
    }),
    prefetchPipClient: alias('product').params({ itemId: stringType() }).shape({
      itemId: stringType()
    })
  });
  const dataModelCombo = extend({}, prefetchPipRef.current.prefetchPip, prefetchPipRef.current.prefetchPipClient);
  const [productRequestClient] = useLazyDataModel('prefetchPipClient', {
    fetchPolicy: 'cache-first',
    dataModel: { prefetchPipClient: dataModelCombo },
    skip: true,
    variables: { storeId, dataSource: 'catalog' }
  });

  const fetchProduct = (itemId) => {
    if (prefetchPip === 'both' || prefetchPip === 'clientOnly') {
      productRequestClient({
        variables: {
          itemId,
          dataSource: 'catalog',
          storeId,
          zipCode: storeZip
        }
      });
    }
    prefetchedSkuSet.current.add(itemId);
  };

  const productPageImport = async () => {
    if (timeoutRef?.current) {
      clearTimeout(timeoutRef.current);
    }
    return import(
      /* webpackChunkName: "product" */
      '../product/ProductContainer'
    );
  };

  const loadProductPageChunk = async () => {
    if (!productPageChunkLoaded) {
      const productPage = await productPageImport();
      prefetchPipRef.current = productPage.ProductDataModel;
      setProductPageChunkLoaded(true);
    }
  };

  useEffect(() => {
    if (prefetchPip) {
      timeoutRef.current = setTimeout(() => {
        loadProductPageChunk();
      }, 5000);
    }
  }, []);

  const onHoverProductPod = (itemId) => {
    if (prefetchPip) {
      loadProductPageChunk();
    }

    if (productPageChunkLoaded && !prefetchedSkuSet.current.has(itemId)) {
      fetchProduct(itemId);
    }
  };

  const mountedFn = ({ mounted }) => {
    if (!isLocalized) return false;
    if (response?.responseLoading) return false;
    return mounted;
  };

  const skipFn = ({ skip, queryName, attributes }) => {
    if (queryName !== 'product') return skip;
    const isResolved = isClientResolved({ queryName: 'searchModel' });
    if (attributes.product.fulfillment) {
      if (isResolved) return skip;
      return true;
    }
    return skip;
  };

  const isInStoreFilterON = hasInStoreFilter(data);
  const isEtchVisNavInPLP = isEtchVisNavOnPLP(data);
  const isDynamicBrandPage = data?.searchModel?.aisles?.length > 0;
  isNoResultsFoundRef.current = isSearch
    && data?.searchModel?.searchReport?.totalProducts === 0
    && !!keyword;

  const maxNumOfPodsToHydrate = 12;

  const productsReduce = (productPods) => {
    let groupOfPods = 0;

    let reduced = productPods?.reduce((hydrator, product) => {
      if (!hydrator[groupOfPods]) {
        hydrator.push([]);
      }
      if (hydrator[groupOfPods].length < maxNumOfPodsToHydrate) {
        hydrator[groupOfPods].push(product);
        if (hydrator[groupOfPods].length === maxNumOfPodsToHydrate) {
          groupOfPods += 1;
        }
      }
      return hydrator;
    }, []);

    return reduced;
  };

  const originalProducts = useRef(productsReduce(products));
  const hydrators = resolved || !originalProducts.current?.length
    ? productsReduce(products)
    : originalProducts.current;

  setDefaultVariables({
    ...variables
  });

  if (isNoResultsFoundRef.current) {
    return (
      <>
        <TrackOrder keyword={keyword} />
        <NoResultsFound
          keyword={keyword}
          requestKey={RECS_REQUEST_KEY}
          searchData={data}
          searchError={error}
          searchLoading={loading}
          storeId={loadedStore.current?.storeId}
          isCategory={isCategory}
          pageType={pageType}
        />
      </>
    );
  }

  const hasDeliveryFilter = (breadCrumbs, filterLabelList) => {
    return (breadCrumbs || []).filter((breadCrumbDetail) => filterLabelList.includes(breadCrumbDetail?.label));
  };

  const applicableFilters = ['Same-Day Delivery', 'Next-Day Delivery', 'Free 1-2 Day Delivery'];
  const hasSameDayFilter = !!(hasDeliveryFilter(data?.searchModel?.taxonomy?.breadCrumbs, applicableFilters).length);

  return (
    <>
      <ErrorBoundary name="browse-search-redirector">
        <BrowseSearchRedirector variables={response?.variables} />
      </ErrorBoundary>
      <div className="sui-max-w-screen-2xl sui-m-auto">
        <ErrorBoundary name="browse-and-search-metadata">
          <Metadata>
            <BrowseSearchMetadata
              data={data}
              loading={loading}
              error={error}
              variables={response?.variables}
              seoGeneratedContentVariables={{ navParam: response?.variables.navParam }}
              pathWithQueryParams={path}
              isTableView={isTableView}
            />
          </Metadata>
        </ErrorBoundary>
        <ErrorBoundary>
          <TrackOrder keyword={keyword} />
        </ErrorBoundary>
        <div className="sui-grid sui-grid-cols-12 sui-gap-x-4 sm:sui-gap-x-8 sui-mx-4 sm:sui-mx-8">
          <div className="sui-col-span-12">
            <ErrorBoundary name="breadcrumbs">
              <div className="sui-py-3 empty:sui-p-0">
                <Breadcrumbs data={data} loading={loading} error={error} useStencilDesign />
              </div>
            </ErrorBoundary>
            <ErrorBoundary name="connected-translator-widget">
              <div className="sui-items-center sui-flex sui-justify-end sui-ml-auto sui-mr-4">
                <ConnectedTranslatorWidget
                  data={data}
                  loading={loading}
                  keyword={keyword}
                  navParam={nValue}
                  pageType={pageType}
                />
              </div>
            </ErrorBoundary>
            <ErrorBoundary name="rental-intent">
              <RentalIntentDesktop data={data} loading={loading} error={error} keyword={keyword} />
            </ErrorBoundary>
          </div>
          <ErrorBoundary name="product-results-group">
            <ProductResults data={data} loading={loading} error={error}>
              <div className="sui-col-span-12">
                <ErrorBoundary name="sponsored-banner-nucleus">
                  <div className="sui-py-3 empty:sui-p-0">
                    <SponsoredBanner
                      browserId={thdCustomer?.mcvisID || ''}
                      pageContext={{
                        label: 'browse-search',
                        data,
                        keyword,
                        isCategory,
                        isBrowse,
                        isSearch
                      }}
                      avoidPreload
                    />
                  </div>
                </ErrorBoundary>
                <ErrorBoundary name="results-header">
                  <ResultsHeader
                    showShopAllLink={isDynamicBrandPage}
                    resultsRef={resultsDimensionsRef}
                  />
                </ErrorBoundary>
                {!isHybridLayout && !isRefinedPage(pathname) && (
                  <ErrorBoundary name="etch-widgets">
                    <div className="sui-pb-3">
                      <EtchWidgets
                        data={data}
                        loading={loading}
                        error={error}
                        keyword={keyword}
                        navParam={nValue}
                        showFavoritesCount
                        storeId={loadedStore.current?.storeId}
                        exclude={['plpSeo']}
                        isCategory={isCategory}
                        isBrowse={isBrowse}
                        isSearch={isSearch}
                      />
                    </div>
                  </ErrorBoundary>
                )}
                {isHybridLayout && !hybridLoading && isBrowse
                  && (
                    <ErrorBoundary name="contentful-widgets">
                      <HybridContentfulWidgets
                        contentElements={contentElements}
                        loading={hybridLoading}
                        isBrowse={isBrowse}
                      />
                    </ErrorBoundary>
                  )}
                {!isEtchVisNavInPLP && viewVizNavFilters && !hybridLoading && !isBrandPage && (
                  <ErrorBoundary name="visual-nav">
                    <div className="sui--mr-4 sm:sui-mx-0 sui-py-3 sui-overflow-auto empty:sui-py-0">
                      <VisualCategoryNavigation
                        data={data}
                      />
                    </div>
                  </ErrorBoundary>
                )}
              </div>
              <div className="sui-col-span-12 sui-py-4">
                {viewPrimaryFilters && isGetItFastOnPlp && (
                  <ErrorBoundary name="results-applied">
                    <div className="sui-grid sui-grid-cols-12 sui-py-3">
                      <div className="sui-col-span-9 sm:sui-col-span-12">
                        <ResultsApplied
                          onAppliedChange={onDimensionsChange}
                          viewPrimaryFilters={viewPrimaryFilters}
                          isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                          isGetItFastOnPlp={isGetItFastOnPlp}
                        />
                      </div>
                    </div>
                  </ErrorBoundary>
                )}
                {!isCategory && !viewPrimaryFilters && (
                  <div className="sui-grid sui-grid-cols-12 sui-py-3">
                    <ErrorBoundary name="top-results-applied">
                      <div className={resultsColSize}>
                        <ResultsApplied
                          onAppliedChange={onDimensionsChange}
                          isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                        />
                      </div>
                    </ErrorBoundary>
                    {layoutStyle !== 'Table' && (
                      <div className="sui-col-span-3 sm:sui-col-span-12">
                        <ResultsSortBy onSortChange={onSortChange} />
                      </div>
                    )}
                    {isTableView && (
                      <div className="sui-col-span-1 sm:sui-col-span-12 sui-p-3">
                        <ResultsToggleLayout
                          activeLayout={layoutStyle}
                          toggleActiveLayout={handleLayoutToggle}
                          isTableView={isTableView}
                          hideListIcon
                        />
                      </div>
                    )}
                  </div>
                )}
                {!isCategory && (
                  <div className="sui-grid sui-grid-cols-12">
                    <ErrorBoundary
                      name="results-dimensions"
                      className="browse-search__left-outer-padding"
                    >
                      {!isGetItFastOnPlp && (
                        <ErrorBoundary name="mf-results-dimensions">
                          <div className="sui-col-span-12 sm:sui-col-span-4
                                md:sui-col-span-3 lg:sui-col-span-2 sui-py-0 sui-pr-3 empty:sui-p-0"
                          >
                            {!hideFFMFilters && (
                              <Hydrator
                                id="browse-search-dimensions"
                              >
                                <ResultsDimensions
                                  onDimensionsChange={onDimensionsChange}
                                  enableMultiStore
                                  shiftCategoryDimension={isDynamicBrandPage}
                                  showOnlyGetItFast={viewAllFilterButton && viewPrimaryFilters}
                                  isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                />
                              </Hydrator>
                            )}
                            {!isInGridBannerEnabled && (
                              <ErrorBoundary name="sponsored-skyscraper-banner">
                                <SponsoredSkyscraperBanner
                                  browserId={thdCustomer?.mcvisID || ''}
                                  pageContext={{
                                    label: 'browse-search',
                                    data,
                                    keyword,
                                    isCategory,
                                    isBrowse,
                                    isSearch
                                  }}
                                  dynamic={{
                                    pageType
                                  }}
                                  hydrator={{
                                    scrollBuffer: HYDRATION_STANDARD_SCROLL_BUFFER
                                  }}
                                />
                              </ErrorBoundary>
                            )}
                          </div>
                        </ErrorBoundary>
                      )}
                      <div className={`sui-p-0 sui-relative ${resultsColClasses}`}>
                        {viewPrimaryFilters && (
                          <>
                            <div className="sui-mb-3 sui-ml-0">
                              <ErrorBoundary name="filter-and-sort-drawer">
                                <ResultsFilterAndSortDrawer
                                  onSortChange={onSortChange}
                                  onDimensionsChange={onDimensionsChange}
                                  disableStickyFilter
                                  excludePickUpToday
                                  isTableView={isTableView}
                                  hideViewAll={!viewAllFilterButton}
                                />
                              </ErrorBoundary>
                            </div>
                            {!isGetItFastOnPlp && (
                              <div className="sui-grid sui-grid-cols-12 sui-mb-3 sui-ml-0">
                                <ErrorBoundary name="top-results-applied">
                                  <div className={`sui-pb-0 ${resultsColSize}`}>
                                    <ResultsApplied
                                      onAppliedChange={onDimensionsChange}
                                      hideResultsCount={viewPrimaryFilters}
                                      isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                      isGetItFastOnPlp={isGetItFastOnPlp}
                                    />
                                  </div>
                                </ErrorBoundary>
                              </div>
                            )}
                          </>
                        )}
                        {
                          isBuyitagainEnabled && (
                            <div>
                              <ErrorBoundary>
                                <div className="sui-grid sui-grid-cols-12 sui-mr-2">
                                  <BuyItAgain
                                    nValue={nValue}
                                    schemaName="buyitagain"
                                    anchorId={`N-${nValue}`}
                                    loading={responseLoading}
                                    data={data}
                                    error={error}
                                    showLoading
                                  />
                                </div>
                              </ErrorBoundary>
                            </div>
                          )
                        }

                        {
                          (viewPrimaryFilters || isBuyitagainEnabled) && (
                            <>
                              {viewPrimaryFilters && isGetItFastOnPlp ? (
                                <>
                                  <div className="sui-grid sui-grid-cols-12 sui-ml-0">
                                    <ErrorBoundary name="mf-results-dimensions">
                                      {!hideFFMFilters && (
                                        <div className={isTableView
                                          ? 'sui-py-3 sui-col-span-12 sm:sui-col-span-11 empty:sui-p-0'
                                          : 'sui-py-3 sui-col-span-12 sm:sui-col-span-9 empty:sui-p-0'}
                                        >
                                          <Hydrator
                                            id="browse-search-dimensions"
                                          >
                                            <ResultsDimensions
                                              onDimensionsChange={onDimensionsChange}
                                              enableMultiStore
                                              shiftCategoryDimension={isDynamicBrandPage}
                                              showOnlyGetItFast={viewAllFilterButton && viewPrimaryFilters}
                                              isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                              isGetItFastOnPlp={isGetItFastOnPlp}
                                              viewPrimaryFilters={viewPrimaryFilters}
                                            />
                                          </Hydrator>
                                        </div>
                                      )}
                                      {layoutStyle !== 'Table' && (
                                        <div
                                          className={hideFFMFilters
                                            ? 'sui-pr-0 sui-col-span-12'
                                            : 'sui-pr-0 sui-col-span-12 sm:sui-col-span-3'}
                                        >
                                          <ResultsSortBy onSortChange={onSortChange} />
                                        </div>
                                      )}
                                    </ErrorBoundary>
                                    <ErrorBoundary name="flooring-drawer">
                                      <div className="sui-grow sui-col-span-12">
                                        <FlooringDrawer products={products} />
                                      </div>
                                    </ErrorBoundary>
                                    <ErrorBoundary name="top-results-applied">
                                      <div className="sui-col-span-12 sui-py-3 empty:sui-p-0">
                                        <ResultsApplied
                                          onAppliedChange={onDimensionsChange}
                                          hideResultsCount={viewPrimaryFilters}
                                          isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                          isGetItFastOnPlp={isGetItFastOnPlp}
                                        />
                                      </div>
                                    </ErrorBoundary>
                                    {isTableView && (
                                      <div className="sui-col-span-12 sm:sui-col-span-1 sui-p-3">
                                        <ResultsToggleLayout
                                          activeLayout={layoutStyle}
                                          toggleActiveLayout={handleLayoutToggle}
                                          isTableView={isTableView}
                                          hideListIcon
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )
                                : (
                                  <div className="sui-grid sui-grid-cols-12 sui-ml-0">
                                    <ErrorBoundary name="top-results-applied">
                                      <div className={resultsColSize}>
                                        <ResultsApplied
                                          onAppliedChange={onDimensionsChange}
                                          viewPrimaryFilters={viewPrimaryFilters}
                                          isFulfillmentRedesignEnabled={isFulfillmentRedesignEnabled}
                                        />
                                      </div>
                                    </ErrorBoundary>
                                    {layoutStyle !== 'Table' && (
                                      <div className="sui-col-span-12 sm:sui-col-span-3 sui-pr-0">
                                        <ResultsSortBy onSortChange={onSortChange} />
                                      </div>
                                    )}
                                    {isTableView && (
                                      <div className="sui-col-span-12 sm:sui-col-span-1 sui-p-3">
                                        <ResultsToggleLayout
                                          activeLayout={layoutStyle}
                                          toggleActiveLayout={handleLayoutToggle}
                                          isTableView={isTableView}
                                          hideListIcon
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                            </>
                          )
                        }
                        <ErrorBoundary name="wrapped-results">
                          <div className="sui--mx-1">
                            <ResultsWrapped>
                              <div ref={resultsDimensionsRef}>
                                {!products?.length && loading && isSearch && (
                                  <ProductPodPlaceholder podsCount={SERVER_SIDE_PRODUCT_POD_RENDER_COUNT} />
                                )}
                                {layoutStyle === 'Table' && (
                                  <ProductTableView categoryNavParam={nValue} storeId={storeId} />
                                )}
                                {layoutStyle === 'Table' && isInGridBannerEnabled && (
                                  <ErrorBoundary name="sponsored-ingrid-nucleus">
                                    <div className="sui-pl-8 empty:sui-p-0">
                                      <SponsoredInGridBanner
                                        browserId={thdCustomer?.mcvisID || ''}
                                        pageContext={{
                                          label: 'browse-search',
                                          data,
                                          keyword,
                                          isCategory,
                                          isBrowse,
                                          isSearch
                                        }}
                                      />
                                    </div>
                                  </ErrorBoundary>

                                )}
                                {!!hydrators?.length && layoutStyle !== 'Table' && (
                                  <QueryProvider
                                    dataSource="searchNav"
                                    cacheKey="search-product-pods-plp-dt"
                                    defaultVariables={{
                                      storeId: loadedStore.current?.storeId,
                                      skipKPF: !showKeyProductFeaturePods,
                                      skipSubscribeAndSave: true,
                                      skipInstallServices: false,
                                      zipCode: loadedStore.current?.zipCode,
                                      isBrandPricingPolicyCompliant: localizedAndClientLoaded.current
                                        ? isCustomerIdentified
                                        : false
                                    }}
                                    mounted={mountedFn}
                                    skip={skipFn}
                                  >
                                    {hydrators?.map((group, hydratorIndex) => {
                                      if (!group?.length) return null;
                                      return (
                                        <Hydrator
                                          id={`browse-search-pods-${hydratorIndex + 1}`}
                                          className="sui-grid sm:sui-grid-cols-2
                                            lg:sui-grid-cols-3 xl:sui-grid-cols-4"
                                          scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                                          patch={!hydratorIndex}
                                          key={`hydrator-${hydratorIndex}`}
                                          // Anytime the first 12 pods are not in viewport i.e.
                                          // loading large Etch content
                                          // then we are susceptible to React removeChild errors as server-side hydrator
                                          // products get updated with client-side hydrator products
                                          immediateRender={!hydratorIndex}
                                        >
                                          <HoistedProductPodGroup
                                            DetailsPod={CustomProductPodDetails}
                                            keyword={keyword}
                                            detailsPodClassName="sui-bg-primary
                                            sui-mb-1
                                            sm:sui-col-span-2
                                            lg:sui-col-span-3
                                            xl:sui-col-span-4"
                                          >
                                            {group?.map((product, itemIndex, productArray) => {
                                              const { itemId, canonicalUrl } = product?.identifiers || {};
                                              if (!itemId) {
                                                return (
                                                  <div
                                                    className="sui-grid sui-py-1 sm:sui-p-1"
                                                    key={`placeholder-${itemIndex}`}
                                                  >
                                                    <RectShape
                                                      style={{
                                                        width: '100%',
                                                        height: '250px',
                                                        marginBottom: '10px'
                                                      }}
                                                      color="#E0E0E0"
                                                    />
                                                    <TextBlock rows={7} color="#E0E0E0" />
                                                  </div>
                                                );
                                              }
                                              const showContentCard = (
                                                (hydratorIndex * maxNumOfPodsToHydrate) + itemIndex === 12
                                              );
                                              const showInGridBanner = (
                                                (hydratorIndex * maxNumOfPodsToHydrate)
                                                + itemIndex === inGridBannerIndex
                                              );
                                              // display discoveryzone1 in the zone1 position.
                                              const showDiscoveryZone1 = (
                                                ((hydratorIndex * maxNumOfPodsToHydrate) + itemIndex
                                                  === discoveryZoneContainerIndex.zone1)
                                              );
                                              // display discoveryzone2/sponsoredContentCarousel in the zone2 position.
                                              const showDiscoveryZone2 = (
                                                ((hydratorIndex * maxNumOfPodsToHydrate) + itemIndex
                                                  === discoveryZoneContainerIndex.zone2)
                                              );
                                              const podPosition = hydratorIndex * maxNumOfPodsToHydrate + itemIndex;
                                              return (
                                                <ErrorBoundary
                                                  index={itemIndex}
                                                  key={`${itemId}-${itemIndex}`}
                                                  id={`plp-product-pod__${itemIndex}`}
                                                  name={`Product Pod: ${itemIndex}`}
                                                >
                                                  {showContentCard
                                                    && (
                                                      <div className="sui-py-1 sm:sui-p-1 empty:sui-py-0
                                                      sm:sui-col-span-2
                                                      lg:sui-col-span-3
                                                      xl:sui-col-span-4"
                                                      >
                                                        <CategoryCardsDesktop
                                                          data={data}
                                                          loading={loading}
                                                          error={error}
                                                          storeId={loadedStore.current?.storeId}
                                                          noMargin
                                                        />
                                                      </div>
                                                    )}

                                                  {showInGridBanner
                                                    && !loading
                                                    && !responseLoading
                                                    && isInGridBannerEnabled
                                                    && (
                                                      <div className="sui-py-1 sm:sui-p-1 empty:sui-py-0
                                                       sm:sui-p-1 sm:sui-mx-1
                                                       sm:sui-col-span-2
                                                       lg:sui-col-span-3
                                                       xl:sui-col-span-4"
                                                      >
                                                        <ErrorBoundary name="sponsored-ingrid-nucleus">
                                                          <SponsoredInGridBanner
                                                            browserId={thdCustomer?.mcvisID || ''}
                                                            pageContext={{
                                                              label: 'browse-search',
                                                              data,
                                                              keyword,
                                                              isCategory,
                                                              isBrowse,
                                                              isSearch
                                                            }}
                                                          />
                                                        </ErrorBoundary>
                                                      </div>
                                                    )}

                                                  {isDiscoveryZonesEligible
                                                    && showDiscoveryZone1
                                                    && (
                                                      <div className="sm:sui-mx-1 empty:sui-mx-0 sm:sui-col-span-2
                                                        lg:sui-col-span-3 xl:sui-col-span-4"
                                                      >
                                                        <DiscoveryZonesWrapper
                                                          data={data}
                                                          loading={loading}
                                                          nValue={nValue}
                                                          schemaName="discoveryZones"
                                                          storeId={loadedStore.current?.storeId}
                                                          zoneId="1"
                                                          errorBoundary
                                                          hydrator={{
                                                            className: `sui-my-1 empty:sui-my-0
                                                          sui-bg-primary sui-grid sui-w-full`,
                                                            delay: 1500,
                                                            scrollBuffer: 500,
                                                            id: 'discovery-zones-1',
                                                          }}
                                                          dynamic={{
                                                            pageType
                                                          }}
                                                        />
                                                      </div>
                                                    )}

                                                  {isBrowse
                                                    && showDiscoveryZone2
                                                    && shouldShowSponsoredCarousel
                                                    && (
                                                      <div className="sm:sui-col-span-2 lg:sui-col-span-3
                                                      xl:sui-col-span-4 sui-pl-2"
                                                      >
                                                        <RetailMediaCarousel
                                                          pageContext={{
                                                            schema: 'discovery_zone',
                                                            data: { itemId },
                                                            isDiscoveryZone: true
                                                          }}
                                                          showSponsoredCarousel
                                                          plaLocation="sponsoredCarouselBrowsePageDiscoveryZone"
                                                          nValue={nValue}
                                                          tntCarouselType={sponsoredCarouselConfig}
                                                          dynamic={{
                                                            pageType
                                                          }}
                                                        />
                                                      </div>
                                                    )}

                                                  {((isBrowse
                                                  && isDiscoveryZonesEnabled
                                                  && !shouldShowSponsoredCarousel)
                                                    || (isSearch && isDiscoveryZonesSearchEnabled))
                                                    && showDiscoveryZone2
                                                    && (
                                                      <div className="sm:sui-mx-1 empty:sui-mx-0 sm:sui-col-span-2
                                             lg:sui-col-span-3 xl:sui-col-span-4"
                                                      >
                                                        <DiscoveryZonesWrapper
                                                          data={data}
                                                          loading={loading}
                                                          nValue={nValue}
                                                          schemaName="discoveryZones"
                                                          storeId={loadedStore.current?.storeId}
                                                          zoneId="2"
                                                          errorBoundary
                                                          hydrator={{
                                                            className: `sui-my-1 empty:sui-my-0
                                                          sui-bg-primary sui-grid sui-w-full`,
                                                            delay: 1500,
                                                            scrollBuffer: 500,
                                                            id: 'discovery-zones-2',
                                                          }}
                                                          dynamic={{
                                                            pageType
                                                          }}
                                                        />
                                                      </div>
                                                    )}
                                                  <div className="sui-grid sui-py-1 sm:sui-p-1 empty:sui-py-0">
                                                    <ImpressionProvider
                                                      key={itemId}
                                                      data={impProductPodData(layoutID)}
                                                    >
                                                      <CustomProductPodResponsive
                                                        itemId={itemId}
                                                        onHover={onHoverProductPod}
                                                        hoverDelay={productPodHoverDelay}
                                                        storeId={loadedStore.current?.storeId}
                                                        position={podPosition}
                                                        itemRecsIndex={itemIndex}
                                                        samplesLoading={samplesLoading}
                                                        hasInStoreFilter={isInStoreFilterON}
                                                        impProductPodData={impProductPodData(layoutID)}
                                                        showProjectQuantity
                                                        hasSameDayFilter={hasSameDayFilter}
                                                        url={canonicalUrl}
                                                        isFulfillmentFilterAndMsgEnabled={isFulfillmentFSEnabled}
                                                      />
                                                    </ImpressionProvider>
                                                  </div>
                                                </ErrorBoundary>
                                              );
                                            })}
                                          </HoistedProductPodGroup>
                                        </Hydrator>
                                      );
                                    })}
                                  </QueryProvider>
                                )}
                              </div>
                            </ResultsWrapped>
                          </div>
                        </ErrorBoundary>
                        <ErrorBoundary>
                          {(products?.length < SERVER_SIDE_PRODUCT_POD_RENDER_COUNT) && layoutStyle !== 'Table' && (
                            <CategoryCardsDesktop data={data} loading={loading} error={error} noMargin />)}
                        </ErrorBoundary>
                        {!loading
                          && !responseLoading
                          && inGridBannerIndex === -1
                          && isInGridBannerEnabled
                          && (
                            <div className="sui-py-1 sm:sui-p-1 empty:sui-py-0
                                  sm:sui-p-1 sm:sui-mx-1 sm:sui-col-span-2
                                  lg:sui-col-span-3 xl:sui-col-span-4"
                            >
                              <ErrorBoundary name="sponsored-ingrid-nucleus">
                                <SponsoredInGridBanner
                                  browserId={thdCustomer?.mcvisID || ''}
                                  pageContext={{
                                    label: 'browse-search',
                                    data,
                                    keyword,
                                    isCategory,
                                    isBrowse,
                                    isSearch
                                  }}
                                />
                              </ErrorBoundary>
                            </div>
                          )}
                        {layoutStyle !== 'Table' && (
                          <ErrorBoundary name="compare">
                            <CompareTray
                              itemIds={selectedForCompare}
                              onRemove={onSelectedForCompare}
                              onCompare={onCompare}
                            />
                          </ErrorBoundary>
                        )}
                        <DynamicComponent pageType={pageType}>
                          {layoutStyle !== 'Table' && (
                            <ErrorBoundary name="pagination">
                              <div className="sui-py-3 empty:sui-p-0">
                                <ResultsPagination
                                  pageSize={searchNavPropsRef.current.pageSize}
                                  onPageChange={onPageChange}
                                  path={path}
                                />
                              </div>
                            </ErrorBoundary>
                          )}
                          {!hideFeedback && (
                            <Hydrator
                              id="feedback"
                              className="sui-grid"
                              scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                            >
                              <ErrorBoundary
                                name="search-feedback"
                              >
                                <ImpressionProvider
                                  key="SearchFeedback"
                                  data={{
                                    id: '',
                                    component: 'SearchFeedback',
                                    name: 'SearchFeedback',
                                    type: 'content'
                                  }}
                                >
                                  <SearchFeedback />
                                </ImpressionProvider>
                              </ErrorBoundary>
                            </Hydrator>
                          )}
                        </DynamicComponent>
                      </div>
                    </ErrorBoundary>
                  </div>
                )}
              </div>
            </ProductResults>
          </ErrorBoundary>
          <div className="sui-col-span-12 sui--mx-4 sm:sui-mx-0">
            {!hybridLoading && !isBrandPage && (
              <ErrorBoundary name="plp-recs-render-placeholder">
                <div className="sui-bg-primary sui-mb-4 empty:sui-mb-0">
                  <PlpRecsRenderPlaceholder
                    requestKey={RECS_REQUEST_KEY}
                    isBrowse={isBrowse}
                    keyword={keyword}
                    itemIds={getBrowseAndSearchItemIds(products)}
                    impressionData={impPLPRecs()}
                    browseNValue={filterBrowseRecs ? nValue : ''}
                  />
                </div>
              </ErrorBoundary>
            )}
            {!hybridLoading && !isBrandPage && !hideSponsoredCarousel && (
              <ErrorBoundary name="sponsored-carousel-nucleus">
                <RetailMediaCarousel
                  breadCrumbs={data?.searchModel?.taxonomy?.breadCrumbs}
                  categorySourceId={data?.searchModel?.metadata?.categoryID}
                  pageContext={{
                    label: 'browse-search',
                    data: {
                      itemIds: getBrowseAndSearchItemIds(products),
                    },
                    keyword,
                    isCategory,
                    isBrowse,
                    isSearch
                  }}
                  nValue={nValue}
                  showSponsoredCarousel
                  dynamic={{
                    pageType
                  }}
                  hydrator={{
                    className: `sui-bg-primary sui-rounded-sm sui-w-full 
                    sui-mb-4 sui-max-w-full sui-overflow-hidden empty:sui-mb-0`,
                    delay: 1500,
                    scrollBuffer: CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER
                  }}
                />
              </ErrorBoundary>
            )}
            {!hybridLoading && (
              <ErrorBoundary name="plp-recs-render-placeholder">
                <div className="sui-bg-primary sui-mb-4 empty:sui-mb-0">
                  <PlpRvRenderPlaceholder
                    isCategory={isCategory}
                    isSearch={isSearch}
                  />
                </div>
              </ErrorBoundary>
            )}
          </div>
          <div className="sui-col-span-12">
            {isBrowse && (
              <ErrorBoundary name="brand-categories">
                <BrandCategories
                  contentId={hybridParams.slug}
                  pageName="plp"
                />
              </ErrorBoundary>
            )}
            {isSearch && (
              <DynamicComponent pageType={pageType}>
                <Hydrator
                  id="search-emt-section"
                  className="sui-my-4"
                  scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                >
                  <ErrorBoundary
                    name="related-search"
                  >
                    <div className="sui-py-3 empty:sui-p-0">
                      <RelatedSearch
                        navParam={nValue}
                        searchData={data}
                        searchError={error}
                        searchLoading={loading}
                        storeId={loadedStore.current?.storeId}
                      />
                    </div>
                  </ErrorBoundary>
                </Hydrator>
              </DynamicComponent>
            )}
            {!isSearch && !hybridLoading && (
              <>
                <ErrorBoundary name="emt-questions-and-answers">
                  <EmtQuestionsAndAnswersImporter
                    nValue={nValue}
                    dynamic={{
                      pageType
                    }}
                    hydrator={{
                      className: 'sui-py-4 empty:sui-p-0'
                    }}
                  />
                </ErrorBoundary>

                <DynamicComponent pageType={pageType}>
                  <Hydrator
                    id="browse-emt-section"
                    waitFor={[EmtGeneratedContentLoad, EmtLinksLoad]}
                    scrollBuffer={HYDRATION_STANDARD_SCROLL_BUFFER}
                  >
                    <ErrorBoundary name="emt-product-category">
                      <div className="sui-py-3 empty:sui-p-0">
                        <EmtGeneratedContent type="productCategoryContent" nvalue={nValue} />
                      </div>
                    </ErrorBoundary>
                    <ErrorBoundary name="etch-seo">
                      <div className="sui-col-span-12 sui-py-3 empty:sui-p-0">
                        <EtchWidgets
                          data={data}
                          error={error}
                          include={['plpSeo']}
                          loading={loading}
                          navParam={`N-${nValue}`}
                        />
                      </div>
                    </ErrorBoundary>
                    <ErrorBoundary name="emt-related-categories">
                      <div className="sui-py-3 empty:sui-p-0">
                        <EmtGeneratedContent type="relatedCategories" nvalue={`N-${nValue}`} nopadding />
                      </div>
                    </ErrorBoundary>
                    <ErrorBoundary name="related-search">
                      <div className="sui-py-3 empty:sui-p-0">
                        <RelatedSearch
                          navParam={nValue}
                          searchData={data}
                          searchError={error}
                          searchLoading={loading}
                          storeId={loadedStore.current?.storeId}
                        />
                      </div>
                    </ErrorBoundary>
                    <ErrorBoundary name="emt-mmg">
                      <div className="sui-py-3 empty:sui-p-0">
                        <EmtLinks path={cleanedPath} />
                      </div>
                    </ErrorBoundary>
                  </Hydrator>
                </DynamicComponent>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
BrowseAndSearch.displayName = 'BrowseAndSearchPage';

BrowseAndSearch.themeProps = {
  hideSponsoredCarousel: bool,
  hideFFMFilters: bool,
  hideFeedback: bool,
};

BrowseAndSearch.defaultThemeProps = {
  hideSponsoredCarousel: false,
  hideFFMFilters: false,
  hideFeedback: false,
};

BrowseAndSearch.propTypes = {
  pageType: string.isRequired,
  prefetchPip: string,
  productPodHoverDelay: number,
  pathname: string.isRequired,
  customerType: string,
  instance: PropTypes.shape({})
};

BrowseAndSearch.defaultProps = {
  prefetchPip: null,
  productPodHoverDelay: 500,
  customerType: 'b2c',
  instance: {}
};

const ProductSearchDataModel = {
  searchModel: params({ keyword: stringType(), navParam: stringType() }).shape({
    products: params({ startIndex: numberType() }).arrayOf(
      CustomProductPodResponsive.dataModel.product
    )
  })
};

BrowseAndSearch.dataModel = extend(
  {
    searchModel: params().shape({
      metadata: shapeType({
        hasPLPBanner: boolType(),
        categoryID: stringType(),
        analytics: shapeType({
          semanticTokens: stringType(),
          dynamicLCA: stringType()
        })
      }),
      products: params().arrayOf(shapeType({
        identifiers: shapeType({
          storeSkuNumber: client(stringType()),
          specialOrderSku: client(stringType())
        }),
        installServices: params({ storeId: stringType(), zipCode: stringType() }).shape({
          scheduleAMeasure: boolType(),
          gccCarpetDesignAndOrderEligible: boolType()
        }),
        // fix datamodel discrepency due to various product-pod versions
        info: shapeType({
          sponsoredMetadata: shapeType({
            sponsoredId: stringType(),
            trackSource: stringType()
          }),
          sponsoredBeacon: shapeType({
            onClickBeacons: arrayOf(stringType()),
            onViewBeacons: arrayOf(stringType())
          })
        }),
      }))
    }),
  },
  OrderSample,
  ResultsDimensions,
  ResultsFilterAndSort,
  ResultsFilterAndSortDrawer,
  BrowseSearchRedirector,
  BrowseSearchMetadata,
  Breadcrumbs,
  SponsoredBanner,
  DiscoveryZonesWrapper,
  EtchWidgets,
  FlooringDrawer,
  VisualCategoryNavigation,
  CategoryCardsDesktop,
  CompareTray,
  EmtGeneratedContentModel,
  EmtQuestionsAndAnswersImporter,
  EmtLinksModel,
  RelatedSearch,
  NoResultsFound,
  RentalIntentDesktop,
  productDefaultResultsDataModel,
  TrackOrder,
  PlpRecsRenderPlaceholder,
  PlpRvRenderPlaceholder,
  ProductTableView,
  HybridContentfulWidgets.dataModel,
  BuyItAgain,
  // This must come last.
  ProductSearchDataModel,
);
